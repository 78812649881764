@use "@themuse/design-system/lib/styles/mixins";
@use "@themuse/design-system/lib/styles/colors" as *;

.contentWell {
    background: $tm-snow;
}

.trendingSearch {
    padding: 0 24px 40px;
    justify-items: center;
    display: grid;
    grid-template-columns: minmax(auto, 1232px);
    justify-content: center;
    width: 100%;
    box-sizing: border-box;

    @include mixins.viewport("sm") {
        padding: 0 10px 30px;

        aside {
            width: 100%;
        }
    }

    @include mixins.viewport("md") {
        padding: 0 10px 40px;

        [class^="trendingSearch_row"] {
            gap: 17%;
        }
    }

    @include mixins.viewport("xl") {
        padding: 0 10px 40px;
    }
}

.multipleCompanyButtonSection {
    padding: 0 24px 40px;
    justify-items: center;
    display: grid;
    grid-template-columns: minmax(auto, 1232px);
    justify-content: center;
    background-color: rgba($tm-blue, 0.09);
    width: 100%;
    box-sizing: border-box;

    @include mixins.viewport("sm") {
        padding: 0 10px 30px;
    }

    @include mixins.viewport("md") {
        padding: 0 10px 40px;
    }

    @include mixins.viewport("xl") {
        padding: 0 10px 40px;
    }
}

.cta {
    width: 252px;
    padding: 15px 42px 14px 43px;
    font-size: 12px;
    line-height: 24px;
}
