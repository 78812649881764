/* stylelint-disable value-no-vendor-prefix */
@use "@themuse/design-system/lib/styles/mixins";
@use "@themuse/design-system/lib/styles/typography" as *;
@use "@themuse/design-system/lib/styles/colors" as *;
@use "styles/ellipsis" as *;

.companyCard {
    background: $neutral-white;
    border-radius: 4px;
    border: 1px solid $tm-silver;
    box-shadow: 0 4px 12px 0 $tm-box-shadow;
    flex-direction: column;
    max-width: 400px;
    position: relative;
    margin: 0 auto;
    width: 100%;

    &:hover {
        box-shadow: 0 1px 4px 0 $tm-box-shadow;
    }

    .header {
        position: relative;

        .hero {
            aspect-ratio: 2 / 3;
            display: block;
            object-fit: cover;
            width: 100%;
            cursor: pointer;
        }
    }

    .logo {
        background: $neutral-white;
        border-radius: 5px;
        border: 1px solid $neutral-40;
        bottom: -20px;
        cursor: pointer;
        display: block;
        height: 80px;
        max-height: 80px;
        max-width: 80px;
        object-fit: contain;
        position: absolute;
        right: 16px;
        width: 80px;
    }

    .content {
        padding: 24px;
        position: relative;

        .name {
            @include ellipsis(2);

            font-family: $barlow;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.5;
            margin: 8px 0 0;
            text-align: left;
            width: 100%;
            color: $tm-dark-gray;
        }

        .description {
            @include ellipsis(3);
            font-family: $nunito;
            font-size: 14px;
            font-weight: 400;
            margin: 8px 0;
            text-align: left;
            color: $tm-dark-gray;
        }

        .footer {
            align-items: flex-start;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 8px;
            justify-content: space-between;
            padding: 11px 0;

            .search {
                align-items: center;
                border: 1px solid $secondary-100;
                color: $secondary-2;
                display: flex;
                gap: 10px;
                height: 40px;
                justify-content: center;
                padding: 4px 16px;
                text-transform: uppercase;
                white-space: nowrap;

                &:hover {
                    background-color: $primary-80;
                    border: 1px solid $primary-80;
                    color: $secondary-100;
                }
            }

            .profile {
                align-items: center;
                border: 1px solid $secondary-100;
                display: flex;
                gap: 10px;
                height: 40px;
                justify-content: center;
                padding: 4px 16px;
                text-transform: uppercase;
                white-space: nowrap;

                &:hover {
                    background-color: $neutral-white;
                    color: $secondary-100;
                }
            }
        }
    }
}
