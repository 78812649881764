@use "@themuse/design-system/lib/styles/colors";
@use "@themuse/design-system/lib/styles/mixins";

.main {
    @include mixins.viewport("sm") {
        background: colors.$tm-dust;
    }
}

.pageHeader {
    position: sticky;
    top: 0;
    z-index: 5800000;
    box-shadow: unset;
}

.header {
    color: colors.$neutral-white;
    background-color: colors.$tm-dark-gray;
    height: auto;
    flex: 1 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;

    @include mixins.viewport("sm") {
        top: 0;
        position: sticky;
        height: 80px;
        z-index: 1;
    }
}

.inputMenuOpen {
    z-index: unset;
}

.sidebar {
    background: colors.$tm-dust;
    bottom: 0;
    display: none;
    height: 100vh;
    left: 0;
    overflow: scroll;
    padding: 20px 20px 130px;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5999999;

    @include mixins.viewport("sm") {
        display: block;
        overflow: initial;
        padding: 20px;
        position: sticky;
        top: 80px;
        height: calc(100vh - 144px);
        width: 260px;
        z-index: auto;
    }
}

.showSidebar {
    composes: sidebar;
    display: block;
}

.content {
    flex: 3 0 0;
    background-color: colors.$tm-snow;
    position: relative;
    min-height: 100vh;
    padding: 0 15px;
}

.filterToggle {
    position: sticky;
    top: 0;
    z-index: 1;

    @include mixins.viewport("sm") {
        display: none;
    }
}
