@use "@themuse/design-system/lib/styles/mixins";
@use "@themuse/design-system/lib/styles/typography" as *;
@use "@themuse/design-system/lib/styles/colors" as *;
@use "styles/ellipsis" as *;

.section {
    padding: 0 24px 40px;
    justify-items: center;
    display: grid;
    grid-template-columns: minmax(auto, 1232px);
    justify-content: center;
    background-color: rgba($tm-blue, 0.09);
    width: 100%;
    box-sizing: border-box;

    @include mixins.viewport("sm") {
        padding: 0 10px 30px;
    }

    @include mixins.viewport("md") {
        padding: 0 10px 40px;
    }

    @include mixins.viewport("xl") {
        padding: 0 10px 40px;
    }

    .heading {
        @include ellipsis(2);
        font-family: $barlow;
        font-size: 32px;
        font-weight: 600;
        line-height: 46px;
        text-align: center;
        margin: 50px 0 26px;
        max-width: 600px;
        color: $secondary-100;
        width: 100%;
    }

    .subheading {
        font-family: $nunito;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin: 0 10px;
        max-width: 600px;
        color: $tm-dark-gray;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-between;
        gap: 32px;
        margin-bottom: 50px;

        @include mixins.viewport("sm") {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include mixins.viewport("md") {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        .sectionTitle {
            @include ellipsis(2);
            color: $secondary-100;
            font-family: $barlow;
            font-size: 32px;
            font-weight: 600;
            grid-column: 1 / -1;
            line-height: 1.5;
            margin: 26px 0 0;
            max-width: 100%;
            text-align: left;
            text-transform: uppercase;

            @include mixins.viewport("sm") {
                grid-column-start: 1;
                grid-column-end: 3;
                width: 100%;
            }

            @include mixins.viewport("md") {
                grid-column-start: 1;
                grid-column-end: 4;
                width: 100%;
            }
        }
    }
}
