@use "@themuse/design-system/lib/styles/mixins";
@use "@themuse/design-system/lib/styles/colors" as *;

.container {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    width: 100%;
    margin-top: 15px;
    padding: 0 24px;
    position: absolute;
    left: 0;

    @media screen and (min-width: 650px) {
        padding: 0;
        position: static;
        margin-top: 32px;
    }

    @include mixins.viewport("lg") {
        flex-direction: column;
        max-width: 515px;
    }
}

.containerOpened {
    composes: container;
    position: fixed;
    top: 64px;
    background-color: $neutral-white;
    padding: 12px 10px 20px 0;
    margin-top: 0;

    & > button:first-of-type {
        height: 64px;
        width: 50px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include mixins.viewport("sm") {
        background-color: transparent;
        position: static;
        flex-direction: column;
        padding: 0;
        margin-top: 32px;

        & > button:first-of-type {
            display: none;
        }
    }
}

.inputContainer {
    margin: 0;
    padding: 5px;
    height: 60px;
    border-radius: 100px;
    border: 2px solid $primary-100;

    * {
        box-sizing: content-box;
    }

    &:focus-within {
        border: 2px solid $secondary-100;
    }

    & > div:first-of-type {
        background-color: $accent2;
        width: 20px;
        height: 20px;
        padding: 14px;
        border-radius: 50%;

        svg {
            width: 19px;
            height: 19px;
        }
    }

    input {
        margin-left: 8px;
        font-size: 14px;
        color: $secondary-100;
        width: 100%;

        &::placeholder {
            color: $secondary-100;
            font-size: 14px;
        }
    }
}

.inputContainerOpened {
    composes: inputContainer;
    border: 2px solid $secondary-100;

    @include mixins.viewport("sm") {
        height: 60px;
    }

    .input {
        margin: 0 0 0 8px;
        width: 100%;
    }
}

.menuOpened {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 100%;
    height: calc(100vh - 85px);
    top: 85px;
    z-index: 5000;

    @include mixins.viewport("sm") {
        display: block;
        position: relative;
        top: 0;
        height: 220px;
    }
}

.inputClearButton {
    position: static;
    display: block;
    height: 40px;
    width: 40px;
}

.closeButton {
    height: 64px;
    width: 50px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuList {
    padding: 0;
}

.listItem {
    position: relative;
    padding: 0;

    svg {
        position: absolute;
        left: 16px;
        top: 12px;
    }

    a {
        margin: 0 0 0 32px;
        display: block;
        padding: 10px;
    }

    &:first-child {
        .listItemText {
            quotes: "\201c" "\201d";

            &::before {
                content: open-quote;
            }

            &::after {
                content: close-quote;
            }
        }
    }

    .listItemText {
        quotes: "\201c" "\201d";
        max-width: calc(100% - 68px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
    }
}

.postLabel {
    margin: 0 0 0 8px;
    font-weight: 400;
    color: $neutral-40;
    vertical-align: top;
}
