@use "@themuse/design-system/lib/styles/mixins" as *;
@use "@themuse/design-system/lib/styles/typography" as *;
@use "@themuse/design-system/lib/styles/colors" as *;

.mayaPromo {
    align-items: center;
    background: $primary-soft-80;
    border-radius: 8px;
    border: 2px solid $primary-100;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: space-between;
    margin: 36px 16px 4px;
    overflow: hidden;
    padding: 32px 24px;
    position: relative;

    @include viewport("sm") {
        flex-direction: row;
        margin: 36px 16px 24px;
    }

    @include viewport("lg") {
        margin: 36px auto 32px;
        max-width: 1232px;
    }
}

.contentWrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
    margin: auto 0;
    overflow: hidden;
    z-index: 1;

    @include viewport("lg") {
        align-self: stretch;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 64px;
        margin: 0;
    }
}

.logo {
    align-self: stretch;
    display: flex;
    height: 129px;
    width: 170px;
}

.contentSection {
    font-family: $nunito;
}

.gradientAccent {
    background: linear-gradient(180deg, rgba(248, 170, 145, 0.5) 0%, rgba(248, 170, 145, 0.5) 100%);
    bottom: 200px;
    filter: blur(96px);
    height: 200px;
    position: absolute;
    right: 0;
    width: 200px;

    @include viewport("sm") {
        bottom: 112px;
        left: 136px;
    }

    @include viewport("lg") {
        bottom: 0;
        left: 100px;
    }
}

.gradientBackground {
    background: linear-gradient(180deg, rgba(156, 228, 253, 0.5) 0%, rgba(156, 228, 253, 0.5) 100%);
    display: none;
    filter: blur(96px);
    height: 400px;
    position: absolute;
    width: 400px;

    @include viewport("sm") {
        bottom: -50px;
        display: block;
        right: -200px;
    }

    @include viewport("md") {
        bottom: -150px;
        right: -150px;
    }

    @include viewport("lg") {
        bottom: -150px;
        right: 155px;
    }
}

.textContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    position: relative;
    z-index: 1;

    @include viewport("lg") {
        padding: 0 100px 0 24px;
    }
}

.description {
    align-self: stretch;
    color: $secondary-100;
    font-family: $nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin: 0;
}

.bold {
    font-weight: 700;
}

.button {
    background: $secondary-100;
    border-radius: 4px;
    border: none;
    color: $neutral-white;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    height: 40px;
    letter-spacing: 1px;
    min-height: 40px;
    padding: 12px 16px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.3s ease-out;
    z-index: 1;

    &:visited {
        color: $neutral-white;
    }

    &:focus,
    &:active,
    &:hover {
        background-color: $secondary-30;
    }
}

.button:focus-visible {
    outline-offset: 2px;
    outline: 2px solid $secondary-100;
}

.promoImage {
    align-self: stretch;
    border-radius: 50%;
    height: 255px;
    width: 255px;
    z-index: 1;

    @include viewport("md") {
        width: 159px;
        height: 159px;
    }
}
