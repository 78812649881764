/* stylelint-disable color-no-hex */
@use "@themuse/design-system/lib/styles/mixins";
@use "@themuse/design-system/lib/styles/colors" as *;
@use "@themuse/design-system/lib/styles/typography" as *;

$custom1: #3E5AAB;
$custom2: #494E5D;

.headerContainer {
    @media screen and (min-width: 650px) {
        height: 471px;
    }

    @include mixins.viewport("md") {
        height: 642px;
    }

    position: relative;
    display: flex;
    height: 365px;
    background-color: $primary-80;
}

.left {
    margin-top: 16px;
    padding: 0 24px 0 32px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (min-width: 650px) {
        align-items: center;
        width: 60%;
        margin-top: 50px;
    }

    @include mixins.viewport("md") {
        margin-top: 120px;
        padding: 0 50px;
        width: 50%;
    }

    > div {
        > [class^="AnimationContainer_animationContainer"] {
            width: 100%;
            min-height: 245px;
            display: flex;
            align-items: center;

            @media screen and (min-width: 650px) {
                max-width: 490px;
            }

            @include mixins.viewport("lg") {
                min-height: auto;
            }
        }
    }

    .h1 {
        @media screen and (min-width: 650px) {
            font-size: 64px;
        }

        font-family: $barlow;
        font-weight: 700;
        color: $secondary-100;
        font-size: 40px;
        line-height: 100%;
        margin: 0 0 16px;

        span {
            font-style: italic;
            color: $custom1;
            white-space: nowrap;
        }
    }

    .subtext {
        @media screen and (min-width: 650px) {
            font-size: 18px;
        }

        @include mixins.viewport("sm") {
            max-width: none;
        }

        font-family: $nunito;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        max-width: 335px;
        color: $custom2;
        margin: 0;
    }
}

.right {
    @media screen and (min-width: 650px) {
        width: 40%;
    }

    @include mixins.viewport("md") {
        width: 50%;
    }

    height: 100%;
    width: 30%;
    min-width: 133px;

    .hero {
        object-position: center;
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-bottom-left-radius: 90px;

        @media screen and (min-width: 650px) {
            border-bottom-left-radius: 180px;
        }

        @include mixins.viewport("sm") {
            border-bottom-left-radius: 230px;
        }
    }

    .images {
        @media screen and (min-width: 650px) {
            border-bottom-left-radius: 180px;
            aspect-ratio: 311 / 376;
        }

        @include mixins.viewport("sm") {
            border-bottom-left-radius: 230px;
        }

        @include mixins.viewport("md") {
            aspect-ratio: unset;
            width: 100%;
            height: 100%;
        }

        aspect-ratio: 133 / 200;
        border-bottom-left-radius: 90px;
        background-size: cover;
        position: relative;
        min-height: 200px;
    }

    .stars {
        @include mixins.viewport("lg") {
            display: block;
            background: transparent url("https://advice-assets.themuse.com/advice-static/images/star-icon.svg") repeat-x center;
            position: absolute;
            width: 142px;
            height: 45px;
            top: 355px;
            left: -70px;
        }

        display: none;
    }
}
