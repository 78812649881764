@use "@themuse/design-system/lib/styles/colors" as *;

@mixin animate() {
    opacity: 0;
    animation: fade-in-up 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-delay: calc(0.1s * var(--line-index) + 1.5s);
    animation-fill-mode: forwards;
}

@keyframes fade-in-up {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.animationContainer {
    opacity: 0;
}

.animation {
    @include animate;
}

.animationContainerChildren {
    margin: 0 auto 42px auto;

    & h2 {
        position: relative;
    }
}
